<template>
  <div ref="container" class="signContainer">
    <div class="title">申请载体管理</div>
    <div class="tableContainer">
      <el-button class="add" @click="down">下载表格</el-button>
      <el-row>
        <el-col class="table">
          <el-table
            :data="tableData"
            border
            header-cell-class-name="headerClass"
            cell-class-name="cellClass"
            style="width: 100%"
          >
            <el-table-column
              label="申请时间"
              min-width="190"
              prop="createTime"
              align="center"
            />
            <el-table-column
              label="姓名"
              min-width="110"
              prop="name"
              align="center"
            />
            <el-table-column
              label="联系电话"
              min-width="154"
              prop="mobile"
              align="center"
            />

            <!-- <el-table-column label="状态" min-width="140">
              <template slot-scope="scope">
                <span :class="scope.row.status===0?'statusAc':''" class="status" @click.stop="handleRun(scope.$index, scope.row)">启用</span>
                <span :class="scope.row.status===1?'statusAc':''" class="status" @click.stop="handleRun(scope.$index, scope.row)">停用</span>
              </template>
            </el-table-column>
            <el-table-column
              label="管理操作"
              min-width="186"
            >
              <template slot-scope="scope">
                <span class="editCommon" @click.stop="handleEdit(scope.row)">编辑</span>
                <span class="editCommon" @click.stop="handleSignEdit(scope.row)">管理报名</span>
                <span class="deleteCommon" @click.stop="handleDelete(scope.$index, scope.row)">删除</span>
              </template>
            </el-table-column> -->
          </el-table>
        </el-col>
      </el-row>
      <div class="pagination" style="text-align: center">
        <el-pagination
          :total="detail.total"
          :page-size="10"
          layout="prev, pager, next"
          @current-change="clickPage"
        />
      </div>
    </div>
  </div>
</template>
<script>
// import EventBus from '@/utils/eventBus'
import { getApplyCarriersList } from "@/apis/user";
import { parseTime } from "@/utils/index";
import { getCookie } from "@/utils/auth";
export default {
  data() {
    return {
      tableHeight: "",
      detail: "",
      tableData: [],
      current: 1,
    };
  },
  mounted() {
    this.getApplyCarriersList(this.current);
  },
  // activated() {
  //   EventBus.$on('needRefrush', data => {
  //     this.current = 1
  //   })
  // },
  methods: {
    getApplyCarriersList(current = 1) {
      getApplyCarriersList({ current, newsId: this.$route.params.newsId }).then(
        (res) => {
          var records = res.result.records;
          records.forEach((item) => {
            item.createTime = parseTime(item.createTime);
          });
          this.tableData = records;
          this.detail = res.result;
        }
      );
    },
    // handleRun(index, row) {
    //   if (row.status === 0) {
    //     row.status = 1
    //   } else {
    //     row.status = 0
    //   }
    //   deleteActivity({
    //     newsId: row.newsId,
    //     status: row.status
    //   }).then(res => {
    //     if (row.status === 0) {
    //       this.$message1('该条活动已启用', 'success')
    //     } else {
    //       this.$message1('该条活动已停用', 'success')
    //     }
    //   })
    // },

    // handleDelete(index, row) {
    //   this.$handleConfirm('请确认是否要删除这条活动?')
    //     .then(() => {
    //       deleteActivity({
    //         newsId: row.newsId,
    //         status: -1
    //       }).then(res => {
    //         this.tableData.splice(index, 1)
    //         this.$message1('该条新闻已活动', 'success')
    //       })
    //     }).catch(err => {
    //       console.log(err)
    //     })
    // },
    // addSign() {
    //   this.$router.push({
    //     name: 'SignAdd'
    //   })
    // },
    clickPage(e) {
      this.getApplyCarriersList(e);
      this.current = e;
    },
    down() {
      var Auth = getCookie("Auth");
      const { newsId } = this.$route.params;
      window.location.href = `https://api.joinzs.com/mpgenerator/gov/downloadApplyCarriers4Csv?newsId=${newsId}&auth=${Auth}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/mixin";
.signContainer {
  padding: 40px 0 40px 0;
  .title {
    letter-spacing: 2px;
    font-size: 22px;
    line-height: 1;
    color: #333;
    font-weight: bold;
    padding-left: 30px;
  }
  .tableContainer {
    overflow: hidden;
    border: solid 1px #d2d2d2;
    margin: 40px 34px 0;
    box-sizing: border-box;
    .add {
      float: none;
      margin: 12px 0 0 14px;
      @include btnCardConfirm(100px, 34px, 14px);
    }
    .table {
      margin-top: 12px;
    }
  }
  .pagination {
    margin-top: 24px;
  }
}
</style>
